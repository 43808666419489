<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Content Web : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-2 mt-md-0"
            @click="tambahContent()"
          >
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Content Web</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- email -->
          <b-form-group label="Title" label-for="article_title">
            <validation-provider #default="{ errors }" name="article_title">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search titles articles"
                  v-model="filter.article_title"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- npa_id -->
          <b-form-group label="Category" label-for="article_category">
            <validation-provider #default="{ errors }" name="article_category">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search category articles"
                  v-model="filter.article_category"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage()"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="editBeritaKegiatan(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="JadikanHeadline(data.item)">
            <feather-icon icon="RssIcon" size="16" />
            <span class="align-middle ml-50">Jadikan Headline</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteBeritaKegiatan(data.item)">
            <feather-icon icon="XSquareIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- edit Content Web-->
    <b-modal
      id="modal-edit-web-content"
      size="xl"
      title="Edit Content Web"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeEditArticles">
        <b-form @submit.prevent>
          <b-form-input
            id="id"
            v-model="updateDataBeritaKegiatan.id"
            placeholder="id"
            hidden
          />
          <b-form-group label="Kategori" label-for="kategori">
            <validation-provider
              #default="{ errors }"
              name="kategori"
              rules="required"
            >
              <v-select
                id="kategori"
                v-model="updateDataBeritaKegiatan.article_category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                :options="kategori"
                placeholder="kategori.."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Publikasi" label-for="publikasi">
            <validation-provider
              #default="{ errors }"
              name="kategori"
              rules="required"
            >
              <v-select
                id="publikasi"
                v-model="updateDataBeritaKegiatan.article_publish"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                :options="publikasi"
                placeholder="publikasi.."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Waktu Publikasi" label-for="waktu_publikasi">
            <validation-provider
              #default="{ errors }"
              name="waktu_publikasi"
              rules="required"
            >
              <flat-pickr
                v-model="updateDataBeritaKegiatan.article_publish_datetime"
                class="form-control"
                :state="errors.length > 0 ? false : null"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Judul" label-for="judul">
            <validation-provider
              #default="{ errors }"
              name="judul"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="Judul"
                  v-model="updateDataBeritaKegiatan.article_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Judul"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Image" label-for="file">
            <validation-provider #default="{ errors }" name="file">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="file"
                  multiple
                  v-model="article_img"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  @change="handlerImage($event)"
                  accpet="image/*"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Konten" label-for="konten">
            <validation-provider
              #default="{ errors }"
              name="konten"
              rules="required"
            >
              <quill-editor
                v-model="updateDataBeritaKegiatan.article_body"
                :state="errors.length > 0 ? false : null"
                :options="snowOption"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateEditArticles"
          >
            Update Content
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- add Content Web-->
    <b-modal
      id="modal-add-web-content"
      size="xl"
      title="Tambah Content"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeAddArticles">
        <b-form @submit.prevent>
          <b-form-group label="Kategori" label-for="kategori">
            <validation-provider
              #default="{ errors }"
              name="kategori"
              rules="required"
            >
              <v-select
                id="kategori"
                v-model="article_category"
                :state="errors.length > 0 ? false : null"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="kategori"
                placeholder="Pilih kategori articles..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Publikasi" label-for="publikasi">
            <validation-provider
              #default="{ errors }"
              name="publikasi"
              rules="required"
            >
              <v-select
                id="publikasi"
                v-model="article_publish"
                :state="errors.length > 0 ? false : null"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="publikasi"
                placeholder="publikasi.."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Waktu Publikasi" label-for="waktu_publikasi">
            <validation-provider
              #default="{ errors }"
              name="waktu_publikasi"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <flat-pickr
                  v-model="article_publish_datetime"
                  class="form-control"
                  :state="errors.length > 0 ? false : null"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Judul" label-for="judul">
            <validation-provider
              #default="{ errors }"
              name="judul"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="judul"
                  v-model="article_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Judul"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Image" label-for="file">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="file"
                  multiple
                  v-model="article_img"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  @change="handlerImage($event)"
                  accpet="image/*"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Konten" label-for="konten">
            <validation-provider
              #default="{ errors }"
              name="konten"
              rules="required"
            >
              <quill-editor
                v-model="article_body"
                :state="errors.length > 0 ? false : null"
                :options="snowOption"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateAddArticles"
          >
            Add Content
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormTimepicker,
  BFormSpinbutton,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BFormTimepicker,
    BFormSpinbutton,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      // lihat peserta
      pesertaCurrentPage: 1,
      pesertaRows: 0,
      pesertaPerPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        "article_category",
        "article_publish_datetime",
        "article_permalink",
        "article_title",
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */

      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],
      imghandler: "",
      search: null,
      updateDataBeritaKegiatan: {},
      publikasi: ["true", "false"],
      kategori: [
        "Berita",
        "Kegiatan",
        "MKEK",
        "SIP",
        "Anggota",
        "PPDS",
        "Mutasi",
        "Profile",
        "Sejarah",
        "Pengurus",
      ],
      formatted: "",
      selected: "",
      snowOption: {
        theme: "snow",
      },
      filter: {
        article_title: "",
        article_category: "",
      },
    };
  },
  methods: {
    validateAddArticles() {
      this.$refs.validateBeforeAddArticles.validate().then((success) => {
        if (success) {
          this.addContentWeb();
        }
      });
    },
    validateEditArticles() {
      this.$refs.validateBeforeEditArticles.validate().then((success) => {
        if (success) {
          this.updateContentWeb();
        }
      });
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getBeritaKegiatan();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    editBeritaKegiatan(item) {
      this.editDataArticles(item);
      this.$bvModal.show("modal-edit-web-content");
    },
    JadikanHeadline(item) {
      this.jadikanHeadline(item);
    },

    tambahContent() {
      this.$bvModal.show("modal-add-web-content");
    },

    handlerImage(e) {
      const { files } = e.target;
      if (files.length) {
        this.createHandlerImage(files[0], (result) => {
          this.imghandler = result;
        });
      }
    },

    createHandlerImage(file, cb) {
      const reader = new FileReader();
      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },

    async getBeritaKegiatan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.berita_kegiatan.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.meta.total;
        this.handleMsgSuccess("Berhasil tampilkan data berita kegiatan");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "wrong sistem!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async jadikanHeadline(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var id = ids.id;
        const { data } = await API.berita_kegiatan.headLine(id);
        this.handleMsgSuccess("Berhasil dijadikan headline");
      } catch (error) {
        this.handleMsgErrors(error, "wrong sistem");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async addContentWeb() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        let body = {
          article_category: this.article_category,
          article_publish: this.article_publish,
          article_title: this.article_title,
          article_body: this.article_body,
          article_publish_datetime: this.article_publish_datetime,
          article_img: this.imghandler,
        };
        await API.berita_kegiatan.addData(body);
        this.handleMsgSuccess("Berhasil Add Berita Kegiatan");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "wrong sistem!");
      } finally {
        this.$bvModal.hide("modal-add-web-content");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async editDataArticles(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var id = ids.id;
        const { data } = await API.berita_kegiatan.editData(id);
        this.updateDataBeritaKegiatan = data;
        this.handleMsgSuccess("Berhasil view edit articles");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "wrong sistem!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async updateContentWeb() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var ids = this.updateDataBeritaKegiatan.id;
        let body = {
          article_category: this.updateDataBeritaKegiatan.article_category,
          article_publish: this.updateDataBeritaKegiatan.article_publish,
          article_title: this.updateDataBeritaKegiatan.article_title,
          article_body: this.updateDataBeritaKegiatan.article_body,
          article_publish_datetime: this.updateDataBeritaKegiatan
            .article_publish_datetime,
          article_img:
            this.imghandler != ""
              ? this.imghandler
              : this.updateDataBeritaKegiatan.article_img,
        };
        await API.berita_kegiatan.updateData(ids, body);
        this.handleMsgSuccess("Berhasil Update Berita Kegiatan");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal update Krip");
      } finally {
        this.$bvModal.hide("modal-edit-web-content");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteBeritaKegiatan(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var id = ids.id;
        const { data } = await API.berita_kegiatan.deleteData(id);
        this.handleMsgSuccess("Berhasil delete berita kegiatan");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "wrong sistem");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getBeritaKegiatan();
  },
  watch: {
    currentPage() {
      this.getBeritaKegiatan();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
